import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { Grid, Paper, Skeleton, Container } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { useGetCategoriesList } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CategoriesCardList from '../categories-card-list';
import CategoriesNewDialog from '../categories-new-dialog';

export default function CategoryListView() {
  const settings = useSettingsContext();

  const dialog = useBoolean();

  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const { data, isPending } = useGetCategoriesList();

  const { t } = useTranslate();

  const renderSkeleton = () => (
    <Grid container wrap="nowrap">
      {[...Array(3)].map((_, index) => (
        <Box key={index} sx={{ width: '33%', marginRight: '1%', my: 5 }}>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: 118 }} />
          <Box sx={{ pt: 0.5 }}>
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        </Box>
      ))}
    </Grid>
  );

  const renderContent = () => {
    if (isPending) {
      return renderSkeleton();
    }
    if (data && data.length > 0) {
      return <CategoriesCardList categories={data} />;
    }
    return (
      <Paper elevation={3} variant="elevation" square sx={{ p: 3 }}>
        <EmptyContent
          filled
          title="0 Categories"
          imgUrl="/assets/icons/empty/ic_secondary_shape.svg"
          action={
            <Button
              variant="contained"
              color="secondary"
              disabled={isAnalyst}
              sx={{ mt: 1 }}
              onClick={dialog.onTrue}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {convertToTitleCase(t('categories_listing_page.new_category'))}
            </Button>
          }
        />
      </Paper>
    );
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.categories')}
        links={[{ name: t('common.dashboard'), href: '#' }, { name: t('common.categories') }]}
        action={
          data &&
          data.length > 0 &&
          !isAnalyst && (
            <Button
              variant="contained"
              onClick={dialog.onTrue}
              color="secondary"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {convertToTitleCase(t('categories_listing_page.new_category'))}
            </Button>
          )
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {renderContent()}

      {dialog.value && data && <CategoriesNewDialog dialog={dialog} />}
    </Container>
  );
}
