import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Link,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { primary } from 'src/theme/palette';
import { createCategory } from 'src/api/categories';
import { CATEGORY_COLORS } from 'src/constants/categories';

import Iconify from 'src/components/iconify';
import { ColorPicker } from 'src/components/color-utils';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

import { ICategoryItem } from 'src/types/category';

import TestSearch from './categoryies-test-search';

type Props = {
  dialog: IUseBooleanReturnType;
};

function CategoryNewDialog({ dialog }: Props) {
  const queryClient = useQueryClient();

  const router = useRouter();

  const NewCategorySchema = Yup.object().shape({
    name: Yup.string().required('Category name is required'),
    instructions: Yup.string().required('Instruction is required'),
    color: Yup.string()
      .oneOf(CATEGORY_COLORS)
      .default(CATEGORY_COLORS[0])
      .required('Color is required'),
    primaryTestId: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }).test('is primary test have value', 'primary test cannot be empty', (val) => {
      if (!val.id || !val.name) return false;
      return true;
    }),
    isPublished: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
    // not required
    icon: Yup.string(),
  });

  const defaultValues = {
    name: '',
    instructions: '',
    icon: '',
    color: CATEGORY_COLORS[0],
    primaryTestId: {
      name: '',
      id: '',
    },
    isPublished: false,
    isActive: false,
  };

  const methods = useForm({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    const { primaryTestId, ...restFormData } = data;

    const finalData: ICategoryItem = {
      ...restFormData,
      primaryTestId: primaryTestId.id!,
    };

    try {
      const resData = await createCategory(finalData);

      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });

      return router.push(`${paths.staff.test.categories.editView(resData.data.id)}?new=true`);
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const closeDialog = () => {
    dialog.onFalse();
  };

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="span" fontWeight="bold">
            {convertToTitleCase(t('categories_listing_page.new_category'))}
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <RHFTextField
            name="name"
            label={t('common.name')}
            size="small"
            margin="dense"
            fullWidth
          />
          <Stack>
            <RHFEditor
              simple
              name="instructions"
              placeholder={t('common.instructions')}
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </Stack>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <FormControl variant="outlined" fullWidth>
                  <Typography
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    {t('common.icon')}
                  </Typography>

                  <RHFTextField
                    name="icon"
                    size="small"
                    id="input-with-icon-textfield"
                    placeholder={t('common.example_code', { code: 'mdi:card-account-details' })}
                    helperText={
                      <Typography variant="caption">
                        Choose an icon from the{' '}
                        <Link
                          href="https://icon-sets.iconify.design/"
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: primary.dark,
                            textDecoration: 'underline',
                            '&:hover': { color: primary.dark },
                          }}
                        >
                          library
                        </Link>{' '}
                        and copy and paste the code here.
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl>
                  <Typography
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    {t('common.colors')}
                  </Typography>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <ColorPicker
                        colors={CATEGORY_COLORS}
                        selected={field.value}
                        onSelectColor={(color) => field.onChange(color as string)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <FormControl variant="outlined" fullWidth>
            <Typography
              component={Typography}
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
            >
              {convertToTitleCase(t('common.primary_test'))}
            </Typography>
            <TestSearch
              name="primaryTestId"
              // query={debouncedSearchText}
              // results={tests as any}
              // searchInput={testSearchText}
              // onSearch={handleSearch}
              // loading={isPending}
              infoText={t('categories_listing_page.new_category_form.primary_test_info')}
            />
          </FormControl>
          <Box sx={{ flexGrow: 1 }} flexDirection="column" display="flex">
            <Typography
              component={Typography}
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
            >
              {t('common.status')}
            </Typography>
            <RHFSwitch
              name="isPublished"
              label={t('categories_listing_page.new_category_form.publish_unpublish')}
            />
            <RHFSwitch
              name="isActive"
              label={t('categories_listing_page.new_category_form.show_hide')}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} variant="outlined" color="inherit">
            {t('common.cancel')}
          </Button>
          <LoadingButton color="success" type="submit" variant="contained" loading={isSubmitting}>
            {t('common.create')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default CategoryNewDialog;
