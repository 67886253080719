import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { m } from 'framer-motion';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { Alert, Collapse } from '@mui/material';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { setCategoryListOrder, toggleCategoryActiveField } from 'src/api/categories';

import { ICategoryList } from 'src/types/category';

import CategoryCard from './category-card';
// import { t } from 'i18next';

// ----------------------------------------------------------------------

type Props = {
  categories: ICategoryList[];
};

export default function CategoriesCardList({ categories }: Props) {
  const [categoryList, setCategoryList] = useState<ICategoryList[]>(categories);

  const [liveUpdatedCategory, setLiveUpdatedCategory] = useState<
    { id: string; field: string; status: boolean }[]
  >([]);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const handleToggleActive = async ({
    id,
    field,
    status,
  }: {
    id: string;
    field: string;
    status: boolean;
  }) => {
    try {
      await toggleCategoryActiveField(id);
      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });
      setLiveUpdatedCategory((prevState) => [{ id, field, status }, ...prevState]);
      setTimeout(() => {
        setLiveUpdatedCategory((prevState) => prevState.filter((cat) => cat.id !== id));
      }, 3000);
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };

  const handleNext = async (index: number) => {
    if (index < categoryList.length - 1) {
      setCategoryList((prev) => {
        const newCategories = [...prev];

        newCategories[index] = newCategories.splice(index + 1, 1, newCategories[index])[0];

        debouncedHandleSetCategoryListOrder([...newCategories]);

        return newCategories;
      });
    }
  };

  const handlePrev = (index: number) => {
    if (index > 0) {
      setCategoryList((prev) => {
        const newCategories = [...prev];

        newCategories[index] = newCategories.splice(index - 1, 1, newCategories[index])[0];

        debouncedHandleSetCategoryListOrder([...newCategories]);

        return newCategories;
      });
    }
  };

  const handleSetCategoryListOrder = useCallback(
    async (newCategories: ICategoryList[]) => {
      try {
        await setCategoryListOrder(newCategories);
        queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });
      } catch (error) {
        const errMsg =
          error?.response?.data?.message ||
          error?.message ||
          'Error re-ordering and updating categories';
        enqueueSnackbar(errMsg, { variant: 'error' });
      }
    },
    [queryClient, enqueueSnackbar],
  );

  // Create a debounced version of handleNext
  const debouncedHandleSetCategoryListOrder = useMemo(
    () => debounce(handleSetCategoryListOrder, 2000),
    [handleSetCategoryListOrder],
  );

  useEffect(() => {
    setCategoryList(categories); // Update state when categories change
  }, [categories]);

  return (
    <>
      <Collapse in={liveUpdatedCategory.length > 0} sx={{ width: 1, mb: { xs: 3, md: 5 } }}>
        {liveUpdatedCategory.map((category) => (
          <Alert
            key={category.id}
            severity="success"
            variant="outlined"
            onClose={() =>
              setLiveUpdatedCategory((prevState) =>
                prevState.filter((cat) => cat.id !== category.id),
              )
            }
            sx={{ mb: 1 }}
          >
            {t('categories_listing_page.category_status_notification', {
              category_field: category.field,
              category_status: category.status
                ? t('common.live').toLowerCase()
                : t('common.inactive').toLowerCase(),
            })}
          </Alert>
        ))}
      </Collapse>
      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {/* Add animation while reordering */}
        {categoryList.map((category, index) => (
          <m.div
            key={category.id}
            animate={{ opacity: 1, x: 0 }}
            layout
            transition={{ type: 'spring', stiffness: 450, damping: 30 }}
            style={{ overflowX: 'hidden', padding: '5px' }}
          >
            <CategoryCard
              key={category.id}
              category={category}
              handleToggleActive={handleToggleActive}
              onNext={() => handleNext(index)}
              onPrev={() => handlePrev(index)}
              isPrevDisabled={index === 0}
              isNextDisabled={index === categories.length - 1}
            />
          </m.div>
        ))}
      </Box>
    </>
  );
}
