import React, { Children } from 'react';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Box,
  Link,
  Stack,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { useTranslate } from 'src/locales';
import queryKeys from 'src/constants/query-keys';
import { deleteStudent } from 'src/api/students';
import { customColors } from 'src/theme/palette';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ITableColumns } from 'src/types/misc';
import { IStudentModifiedItem } from 'src/types/students';

type Props = {
  row: IStudentModifiedItem;
  headLabel: Partial<ITableColumns>[] | any;
  isPremiseStudentTab?: boolean;
};

export default function StudentTableRow({ row, headLabel, isPremiseStudentTab = false }: Props) {
  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const navigate = useNavigate();

  const handleDeleteStudent = async () => {
    try {
      confirm.onFalse();
      await deleteStudent(row.id);
      enqueueSnackbar(t('student_listing_page.student_deleted'));
      queryClient.invalidateQueries({
        queryKey: [
          !isPremiseStudentTab
            ? queryKeys.staff.students.fetchAllStudents
            : queryKeys.staff.premises.fetchStudentsByPremiseId,
        ],
      });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const redirectUrl = paths.staff.studentDetails(row.id);

  const handleEdit = async () => {
    navigate(redirectUrl);
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('student_listing_page.edit_student')} placement="top" arrow>
        <IconButton onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('student_listing_page.delete_student')} placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }

            if (headCell.id) {
              if (!isPremiseStudentTab && headCell.id === 'fullName') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="subtitle2" fontWeight={900}>
                      <Link
                        color="inherit"
                        underline="hover"
                        component={RouterLink}
                        href={paths.staff.studentDetails(row?.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {/* @ts-ignore */}
                        {row?.[headCell?.id]}
                      </Link>
                    </Typography>
                  </TableCell>
                );
              }

              if (isPremiseStudentTab && headCell.id === 'fullName') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="subtitle2">
                      {/* @ts-ignore */}
                      {row?.[headCell?.id]}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'email') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                      color: 'customColors.custom1',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* <Link> */}
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                    {/* </Link> */}
                  </TableCell>
                );
              }

              if (headCell.id === 'level') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {row.level && (
                      <Label
                        variant="soft"
                        sx={{
                          backgroundColor: lightenHexColor(customColors.custom2, 84),
                          color: 'customColors.custom2',
                          fontWeight: '900',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                      >
                        {row.level}
                      </Label>
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'phone') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      color: 'text.secondary',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                  </TableCell>
                );
              }

              if (!isPremiseStudentTab && headCell.id === 'premises') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id].length ? (
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2" whiteSpace="wrap">
                          {/* @ts-ignore */}
                          {row?.premises.join(', ')}
                        </Typography>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              {t('student_listing_page.delete_title')}
            </Typography>
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1" fontWeight={400}>
              {t('student_listing_page.sure_delete')}
            </Typography>
            <Typography variant="body1">
              {t('student_listing_page.sure_delete_description')}
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteStudent}>
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
