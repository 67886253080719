import Iconify, { IconifyProps } from '../iconify';

// ----------------------------------------------------------------------

type Props = {
  icon?: IconifyProps; // Right icon
  isRTL?: boolean;
  isDisabled?: boolean;
};

export function LeftIcon({ icon = 'eva:arrow-ios-forward-fill', isRTL, isDisabled }: Props) {
  return (
    <Iconify
      icon={icon}
      sx={{
        transform: ' scaleX(-1)',
        opacity: isDisabled ? 0.2 : 1,
        ...(isRTL && {
          transform: ' scaleX(1)',
        }),
      }}
    />
  );
}

export function RightIcon({ icon = 'eva:arrow-ios-forward-fill', isRTL, isDisabled }: Props) {
  return (
    <Iconify
      icon={icon}
      sx={{
        ...(isRTL && {
          transform: ' scaleX(-1)',
        }),
        opacity: isDisabled ? 0.2 : 1,
      }}
    />
  );
}
