import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Button,
  Divider,
  Typography,
  FormControl,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { primary } from 'src/theme/palette';
import keys from 'src/constants/query-keys';
import { useAuthContext } from 'src/auth/hooks';
import { updateCategory } from 'src/api/categories';
import { USER_ROLES } from 'src/constants/user-roles';
import { CATEGORY_COLORS } from 'src/constants/categories';

import { ColorPicker } from 'src/components/color-utils';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

import { ICategoryItem } from 'src/types/category';

function CategoryEditViewGeneral({ category }: { category: ICategoryItem }) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();

  const EditCategorySchema = Yup.object().shape({
    name: Yup.string().required(t('categories_edit_page.general.form.name_required')),
    instructions: Yup.string().required(
      t('categories_edit_page.general.form.instructions_required'),
    ),
    color: Yup.string()
      .oneOf(CATEGORY_COLORS)
      .required(t('categories_edit_page.general.form.color_required')),
    isPublished: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
    // not required
    icon: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: category?.name || '',
      instructions: category?.instructions || '',
      icon: category?.icon || '',
      color: category?.color || CATEGORY_COLORS[0],
      isPublished: category?.isPublished || false,
      isActive: category?.isActive || false,
    }),
    [category],
  );

  const methods = useForm({
    resolver: yupResolver(EditCategorySchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
  } = methods;

  const isPublished = watch('isPublished');
  // const isActive = watch('isActive');

  useEffect(() => {
    if (!isPublished) {
      setValue('isActive', false);
    }
  }, [isPublished, setValue]);

  useEffect(() => {
    if (category) {
      reset({ ...defaultValues });
    }
  }, [category, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const finalData: Omit<ICategoryItem, 'primaryTestId'> = {
        id: category.id,
        ...data,
        orderId: category.orderId,
      };

      await updateCategory(finalData);

      queryClient.invalidateQueries({
        queryKey: [keys.staff.categories.fetchCategory, category.id],
      });
      enqueueSnackbar(t('common.update_success'), { variant: 'success' });
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Paper elevation={3} variant="elevation" square sx={{ p: 3, my: 5 }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Typography component={Typography} variant="subtitle2" fontWeight="fontWeightBold">
            {t('categories_edit_page.general.title')}
          </Typography>
          <RHFTextField
            name="name"
            label={t('common.name')}
            margin="none"
            disabled={isAnalyst}
            fullWidth
          />
          <Stack>
            <RHFEditor
              simple
              name="instructions"
              placeholder={t('common.instructions')}
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
              readOnly={isAnalyst}
            />
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', my: 2 }} />
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <FormControl variant="outlined" fullWidth>
                  <Typography
                    // id="demo-customized-radios"
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    {t('common.icon')}
                  </Typography>

                  <RHFTextField
                    name="icon"
                    size="small"
                    id="input-with-icon-textfield"
                    placeholder={t('common.search')}
                    helperText={
                      <Typography variant="caption">
                        Choose an icon from the{' '}
                        <Link
                          href="https://icon-sets.iconify.design/"
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: primary.dark,
                            textDecoration: 'underline',
                            '&:hover': { color: primary.dark },
                          }}
                        >
                          library
                        </Link>{' '}
                        and copy and paste the code here.
                      </Typography>
                    }
                    disabled={isAnalyst}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <Iconify icon="eva:search-fill" />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl>
                  <Typography
                    // id="demo-customized-radios"
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    {t('common.colors')}
                  </Typography>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <ColorPicker
                        colors={CATEGORY_COLORS}
                        selected={field.value}
                        onSelectColor={(color) => !isAnalyst && field.onChange(color as string)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} flexDirection="column" display="flex">
            <Typography
              // id="demo-customized-radios"
              component={Typography}
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
            >
              {t('common.status')}
            </Typography>
            <RHFSwitch
              name="isPublished"
              label={t('categories_edit_page.general.form.publish_unpublish')}
              disabled={isAnalyst}
            />
            <RHFSwitch
              name="isActive"
              label={t('categories_edit_page.general.form.show_hide')}
              disabled={!isPublished || isAnalyst}
            />
          </Box>
        </Stack>
      </Paper>
      <Stack display={isAnalyst ? 'none' : 'flex'} justifyContent="end" flexDirection="row" gap={2}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          disabled={!isDirty}
          loading={isSubmitting}
        >
          {t('common.save')}
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={() => reset()}>
          {t('common.cancel')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CategoryEditViewGeneral;
